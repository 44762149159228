<template>
  <div class="login">
    <header-view></header-view>
    <div class="content">
      <div class="box">
        <!-- <div class="login-img">
          <img src="../../assets/images/login.png" alt="" />
        </div> -->
        <div class="title">登录</div>
        <div class="right">
          <el-form :model="loginForm" :rules="loginRules" ref="loginForm">
            <el-form-item prop="phone">
              <el-input
                placeholder="请输入手机号"
                v-model="loginForm.phone"
                @keyup.enter.native="handleLogin"
              ></el-input>
            </el-form-item>
            <el-form-item prop="password">
              <el-input
                type="password"
                placeholder="请输入登录密码"
                v-model="loginForm.password"
                @keyup.enter.native="handleLogin"
              ></el-input>
            </el-form-item>
          </el-form>
          <div class="boxs">
            <div class="zhuce" @click="handleZhu">注册账号</div>
            <div class="wj" @click="handleForget">忘记密码？</div>
          </div>

          <el-button
            :loading="loading"
            @click.native.prevent="handleLogin"
            class="btn"
            >立即登录</el-button
          >
        </div>
      </div>
    </div>
    <footer-view></footer-view>
  </div>
</template>

<script>
import HeaderView from "@/components/HeaderView";
import FooterView from "@/components/FooterView";
import { login } from "@/api/index";
export default {
  components: {
    HeaderView,
    FooterView,
  },
  data() {
    const validateUsername = (rule, value, callback) => {
      if (value.length == 0) {
        callback(new Error("请输入账号"));
      } else {
        callback();
      }
    };
    const validatePass = (rule, value, callback) => {
      if (value.length < 5) {
        callback(new Error("密码不能小于5位"));
      } else {
        callback();
      }
    };
    return {
      loginForm: {
        password: "",
        phone: "",
      },
      loginRules: {
        phone: [
          { required: true, trigger: "blur", validator: validateUsername },
        ],
        password: [
          { required: true, trigger: "blur", validator: validatePass },
        ],
      },
      loading: false,
    };
  },
  methods: {
    handleLogin() {
      this.$refs.loginForm.validate((valid) => {
        if (valid) {
          this.loading = true;
          login(this.loginForm)
            .then((res) => {
              this.loading = false;
              localStorage.setItem("GFtoken", res.token);
              // localStorage.setItem("AGsessionId", res.data.sessionId);
              // localStorage.setItem("AGuserInfo", JSON.stringify(res.data.userInfo));
              setTimeout(() => {
                this.$router.replace({ path: "/" });
              }, 1000);
            })
            .catch((err) => {
              this.loading = false;
            });
        } else {
          return false;
        }
      });
    },
    handleZhu() {
      this.$router.push("Register");
    },
    handleForget() {
      this.$router.push("Forget");
    },
  },
};
</script>

<style lang="scss" scoped>
@media (max-width: 768px) {
.box{
  width: 100% !important;
}
.right{
  width: 100% !important;
}
}
.login {
  background: #f9f9f9;
}
.content {
  height: calc(100vh - 100px);
  background: url("../../assets/images/logo-bg.jpg");
  background-size: cover;
  backgtound-repeat: no-repeat;
  .box {
    width: 1170px;
    margin-right: auto;
    margin-left: auto;
    display: flex;
    padding-top: 20px;
    box-sizing: border-box;
    height: calc(100vh - 100px);
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .title {
      color: #fff;
      font-size: 26px;
      display: flex;
      justify-content: center;
      margin-bottom: 25px;
      letter-spacing: 10px;
    }
    .right {
      width: 41%;
      flex-shrink: 0;
      padding: 0 50px;
      box-sizing: border-box;
    }
    .boxs {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .zhuce {
        color: #fff;
        font-size: 16px;
        cursor: pointer;
      }
      .wj {
        color: #fff;
        font-size: 16px;
        cursor: pointer;
      }
    }

    .btn {
      width: 100%;
      height: 52px;
      background: #11aa8c;
      color: #fff;
      font-size: 20px;
      margin-top: 20px;
      border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
</style>
